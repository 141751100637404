pre.pre {
  line-height: 20px;
  font-size: 16px;
  color: #58616b;
  background-color: #f8fbfd;
  margin: 0;
  padding: 16px 0;
  overflow-x: auto;
}

pre.inlinePre {
  display: inline-block;
  background-color: #d4e5fe;
  color: #0631cb;
  padding: 0 5px;
}

pre.pre > div:before {
  counter-increment: line;
  display: inline-block;
  border-right: 1px solid #bdd7f2;
  padding-right: 10px;
  margin-right: 10px;
  color: #97aeb8;
  user-select: none;
}

pre.pre > div:nth-of-type(-n + 999):before {
  content: counter(line);
}

pre.pre > div:nth-of-type(-n + 99):before {
  content: " " counter(line);
}

pre.pre > div:nth-of-type(-n + 9):before {
  content: "  " counter(line);
}
