.compactLabel {
  display: flex;
  align-items: center;
}

.label {
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid #bacdd7;
  width: 250px;
  padding: 0 10px;
  border-radius: 3px;
}

.icon {
  position: absolute;
  right: 10px;
  bottom: 8px;
}

.compactIcon {
  margin-left: 5px;
}

.itemsContainer {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
  box-shadow: 0 0 5px #dadada;
  min-width: 150px;
  border-radius: 5px;
  background-color: #fff;
}

.item {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #dbedf6;
  padding: 0 10px;
}

.item svg {
  font-size: 12px;
}

.item:hover {
  cursor: pointer;
  background-color: #f8fbfd;
}

.disabled {
  cursor: not-allowed;
  background-color: #f8fbfd;
}
