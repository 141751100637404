.actionBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.error {
    flex: 1;
    text-align: right;
    margin-right: 20px;
    color: #FD3751;
}