.createExercise {
    display: flex;
    align-items: flex-end;
}

.createExercise > div {
    margin-right: 10px;
}

.createExercise > div:last-child {
    margin-right: 0;
}

.actionBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action {
    color: #0631CB;
}

.deleteAction {
    color: #FD3751;
}