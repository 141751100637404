div.spacerXsm.horizontal,
div.spacerSm.horizontal,
div.spacerMd.horizontal,
div.spacerLg.horizontal,
div.spacerXLg.horizontal
{
    display: inline-block;
    height: 0;
}

div.spacerXsm {
    height: 5px;
    width: 5px;
    flex-shrink: 0;
}

div.spacerSm {
    height: 10px;
    width: 10px;
    flex-shrink: 0;
}

div.spacerMd {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
}

div.spacerLg {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
}

div.spacerXLg {
    height: 80px;
    width: 80px;
    flex-shrink: 0;
}