.support {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #2734a8;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
