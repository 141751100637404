h1.h1 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  margin: 0;
  display: inline;
  color: #05080d;
}

h3.h3 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
  display: inline;
  color: #010d17;
}

h4.h4 {
  font-size: 18px;
  font-weight: normal;
  margin: 0;
  display: inline;
  color: #010d17;
}

h5.h5 {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  display: inline;
  color: #010d17;
  text-transform: uppercase;
}

h1.h1.muted,
h3.h3.muted,
h4.h4.muted,
h5.h5.muted,
p.p.muted {
  color: #a3a3a4;
}

p.p {
  margin: 0;
  color: #505050;
}

span.subtitle {
  color: #5e7182;
}

span.success {
  color: #1cd7c3;
}

span.error {
  color: #fd3751;
}
