.dropzone {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #BDD7F2;
    border-radius: 5px;
    min-height: 125px;
    outline: none;
}

.fileList {
    flex: 1;
}

.fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 10px 20px 0 20px;
}

.trashIcon {
    color: #FD3751;
    cursor: pointer;
}

.dropzone.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: dashed;
}

.dropzone.active {
    background-color: #DAEDF6;
}

.uploadIcon {
    font-size: 36px;
    color: #0631CB;
}

.uploadBar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-top: 1px solid #BDD7F2;
    margin-top: 10px;
}

.uploadBar svg {
    color: #0631CB;
    margin-right: 5px;
}