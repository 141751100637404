.container {
  display: inline-block;
  cursor: default;
}

.checkbox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #466ada;
  border-radius: 5px;
  font-size: 12px;
  color: #466ada;
}

.checkbox.pro {
  border: 1px solid #ff9a4b;
  color: #ff9a4b;
}

.checkbox.disabled {
  background: #f1f7fc;
  cursor: not-allowed;
}

.label {
  margin-left: 5px;
}
