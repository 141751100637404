.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f8fbfd;
}

.padded {
  padding: 20px;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;
  border-bottom: 2px solid #eaf4f9;
  background-color: #fff;
  font-size: 14px;
}

.saveStatus {
  margin-left: 10px;
  margin-top: 4px;
  font-size: 12px;
  color: #1d6b99;
}
