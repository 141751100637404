.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    padding: 40px 0;
}

.centered {
    text-align: center;
}

.forgotPassword {
    width: 250px;
    text-align: left;
    font-size: 14px;
}