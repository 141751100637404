.failedIcon {
    color: #FD3751;
    margin-right: 5px;
}

.successIcon {
    color: #1CD7C3;
    margin-right: 5px;
}

.result {
    display: flex;
    align-items: center;
}

.actualOutput {
    margin-left: 20px;
}