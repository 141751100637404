.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 1000px;
}

.header {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background: #0e1352;
}

.header,
.header h1,
.header h3 {
  color: #fff;
  font-weight: normal;
}

.header > div {
  margin-right: 15px;
}

.split {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.split > div:nth-child(1) {
  flex: 1;
}

.workspaceTestCasesContainer {
  display: flex;
  flex-direction: column;
}

.workspaceContainer {
  position: relative;
}

.runTestsContainer {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.exerciseOverviewContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 440px;
  background-color: #f1f7fc;
  max-height: 100%;
  overflow-y: auto;
}

.exerciseOverviewToolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 40px;
  padding: 0 20px;
  border-bottom: 2px solid #eaf4f9;
  background-color: #fff;
  font-size: 14px;
}

.exerciseOverview {
  flex: 1;
  padding: 20px;
  color: #000;
  border-left: 1px solid #dbedf6;
  overflow: auto;
}

.testCasesContainer {
  border-top: 1px solid #dbedf6;
  z-index: 999;
}

.actionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  flex-basis: 100px;
  flex-shrink: 0;
  border-top: 1px solid #dbedf6;
}
