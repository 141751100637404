.container {
    font-size: 14px;
}

.actionBar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.ioFiles {
    display: flex;
    justify-content: space-between;
}

.ioFiles > div:first-child {
    margin-right: 20px;
}

.ioFiles > div {
    flex: 1;
}