.container {
    width: 100%;
    text-align: left;
}

.text {
    display: block;
    height: 36px;
    border: 1px solid #BACDD7;
    border-radius: 3px;
    padding: 10px;
    color: #58616B;
    width: 100%;
    min-height: 100px;
}

.text::placeholder {
    color: #a9c0cf;
}