h1.Text_h1__2V5me {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  margin: 0;
  display: inline;
  color: #05080d;
}

h3.Text_h3__1BAfH {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
  display: inline;
  color: #010d17;
}

h4.Text_h4__3pIbZ {
  font-size: 18px;
  font-weight: normal;
  margin: 0;
  display: inline;
  color: #010d17;
}

h5.Text_h5__1L83P {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  display: inline;
  color: #010d17;
  text-transform: uppercase;
}

h1.Text_h1__2V5me.Text_muted__2USY9,
h3.Text_h3__1BAfH.Text_muted__2USY9,
h4.Text_h4__3pIbZ.Text_muted__2USY9,
h5.Text_h5__1L83P.Text_muted__2USY9,
p.Text_p__2KLCY.Text_muted__2USY9 {
  color: #a3a3a4;
}

p.Text_p__2KLCY {
  margin: 0;
  color: #505050;
}

span.Text_subtitle__2I4hw {
  color: #5e7182;
}

span.Text_success__1eBsA {
  color: #1cd7c3;
}

span.Text_error__2grGW {
  color: #fd3751;
}

div.Spacer_spacerXsm__3oL9I.Spacer_horizontal__3P5it,
div.Spacer_spacerSm__3EtOW.Spacer_horizontal__3P5it,
div.Spacer_spacerMd__APwks.Spacer_horizontal__3P5it,
div.Spacer_spacerLg__1x5N0.Spacer_horizontal__3P5it,
div.Spacer_spacerXLg__24J1v.Spacer_horizontal__3P5it
{
    display: inline-block;
    height: 0;
}

div.Spacer_spacerXsm__3oL9I {
    height: 5px;
    width: 5px;
    flex-shrink: 0;
}

div.Spacer_spacerSm__3EtOW {
    height: 10px;
    width: 10px;
    flex-shrink: 0;
}

div.Spacer_spacerMd__APwks {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
}

div.Spacer_spacerLg__1x5N0 {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
}

div.Spacer_spacerXLg__24J1v {
    height: 80px;
    width: 80px;
    flex-shrink: 0;
}
.Progress_container__13eKT {
    display: flex;
}

.Progress_progress__vD6i- {
    height: 10px;
    width: 100px;
    background-color: rgba(28, 215, 195, 0.2);
    margin-right: 2px;
}

.Progress_progress__vD6i-.Progress_completed__2na46 {
    background-color: rgba(28, 215, 195, 1.0);
}

.Progress_container__13eKT > .Progress_progress__vD6i-:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Progress_container__13eKT > .Progress_progress__vD6i-:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 0;
}
.Popover_container__NQXX2 {
    display: inline-block;
    position: relative;
}

.Popover_button__2-sFU {
    cursor: pointer;
}

.Popover_popover__36bzg {
    position: absolute;
    z-index: 999;
    -webkit-animation: Popover_fadein__3dl8w 0.1s ease-in;
            animation: Popover_fadein__3dl8w 0.1s ease-in;
}

.Popover_layover__3I2PX {
    position: absolute;
    z-index: 998;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@-webkit-keyframes Popover_fadein__3dl8w {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes Popover_fadein__3dl8w {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.Label_label__3QtSx {
    display: inline-block;
    padding-bottom: 5px;
    color: #2F3B47;
    font-size: 14px;
    font-weight: bold;
}
.Select_compactLabel__2C-dh {
  display: flex;
  align-items: center;
}

.Select_label__1IbUu {
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid #bacdd7;
  width: 250px;
  padding: 0 10px;
  border-radius: 3px;
}

.Select_icon__27-SL {
  position: absolute;
  right: 10px;
  bottom: 8px;
}

.Select_compactIcon__2K5s- {
  margin-left: 5px;
}

.Select_itemsContainer__2wYqQ {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
  box-shadow: 0 0 5px #dadada;
  min-width: 150px;
  border-radius: 5px;
  background-color: #fff;
}

.Select_item__317ew {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #dbedf6;
  padding: 0 10px;
}

.Select_item__317ew svg {
  font-size: 12px;
}

.Select_item__317ew:hover {
  cursor: pointer;
  background-color: #f8fbfd;
}

.Select_disabled__1SPkC {
  cursor: not-allowed;
  background-color: #f8fbfd;
}

.Button_btn__1vpBa {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: #0631ca;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  height: 50px;
  line-height: 50px;
  min-width: 150px;
  border-radius: 25px;
  padding: 0 30px;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  outline: none;
}

.Button_link__7qshC {
  color: #0631ca;
  cursor: pointer;
}

.Button_link__7qshC.Button_small__p0MNE {
  font-size: 12px;
}

.Button_btn__1vpBa.Button_pro__1EJA6,
.Button_btn__1vpBa.Button_pro__1EJA6:hover {
  background-color: #ff9a4b;
}

.Button_btn__1vpBa.Button_pro__1EJA6:focus {
  box-shadow: 0 0 3px 1px #ff9a4b;
}

.Button_btn__1vpBa.Button_disabled__3RSQk,
.Button_btn__1vpBa.Button_disabled__3RSQk:hover {
  cursor: not-allowed;
  background-color: #58616b;
}

.Button_btn__1vpBa > svg {
  margin-right: 5px;
}

.Button_btn__1vpBa.Button_danger__5Q-c5 {
  background: #fd3751;
  color: #fff;
}

.Button_btn__1vpBa.Button_danger__5Q-c5.Button_secondary__14gD5 {
  background: transparent;
  color: #fd3751;
  border: 1px solid #fd3751;
}

.Button_btn__1vpBa.Button_secondary__14gD5 {
  background: transparent;
  color: #0631ca;
  border: 1px solid #0631ca;
}

.Button_btn__1vpBa.Button_secondary__14gD5.Button_pro__1EJA6 {
  color: #ff9a4b;
  border: 1px solid #ff9a4b;
}

.Button_btn__1vpBa.Button_small__p0MNE {
  height: 36px;
  min-width: 0;
  min-width: initial;
  padding: 5px 20px;
  font-size: 14px;
  line-height: normal;
  line-height: initial;
}

a.Button_btn__1vpBa:hover {
  text-decoration: none;
}

.Button_btn__1vpBa.Button_wide__CdUPT {
  width: 100%;
}

.Button_btn__1vpBa:focus {
  box-shadow: 0 0 3px 1px #0631ca;
}

.Button_btn__1vpBa:hover {
  background: #0633ff;
}

.Button_btn__1vpBa.Button_danger__5Q-c5:focus {
  box-shadow: 0 0 3px 1px #fd505a;
}

.Button_btn__1vpBa.Button_danger__5Q-c5:hover {
  background: #fd505a;
}

.Button_btn__1vpBa.Button_secondary__14gD5:hover {
  background: #f4f4f4;
}

.ActionBar_actionBar__EyrZf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.ActionBar_error__2TkVF {
    flex: 1 1;
    text-align: right;
    margin-right: 20px;
    color: #FD3751;
}
.Tabs_tabList__1Oa95 {
    margin: 0;
    padding: 0;
    flex: 1 1;
}

li.Tabs_tab__3GNTS {
    display: inline-flex;
    align-items: center;
}

li.Tabs_tab__3GNTS > svg {
    margin-right: 5px;
    font-size: 14px;
}

li.Tabs_selectedTab__3rGPk {
    color: #153BD2;
    font-weight: bold;
    border-bottom: 3px solid #153BD2;
    height: 40px;
}
.Icons_delete__1shgJ {
  color: #fd3751;
}

.Icons_list__39J4R {
  font-size: 12px;
}

.Icons_pro__2goHS {
  color: #ff9a4b;
}

.Pill_pill__3AHDU {
  padding: 5px 10px;
  background-color: #b1cfff;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
  border-radius: 12px;
}

.Dropzone_dropzone__1DCqr {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #BDD7F2;
    border-radius: 5px;
    min-height: 125px;
    outline: none;
}

.Dropzone_fileList__3nDda {
    flex: 1 1;
}

.Dropzone_fileItem__3ra8F {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 10px 20px 0 20px;
}

.Dropzone_trashIcon__2Q0cc {
    color: #FD3751;
    cursor: pointer;
}

.Dropzone_dropzone__1DCqr.Dropzone_empty__3DP_n {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: dashed;
}

.Dropzone_dropzone__1DCqr.Dropzone_active__3zJ40 {
    background-color: #DAEDF6;
}

.Dropzone_uploadIcon__FfYY5 {
    font-size: 36px;
    color: #0631CB;
}

.Dropzone_uploadBar__3OXCY {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-top: 1px solid #BDD7F2;
    margin-top: 10px;
}

.Dropzone_uploadBar__3OXCY svg {
    color: #0631CB;
    margin-right: 5px;
}
pre.Pre_pre__14neh {
  line-height: 20px;
  font-size: 16px;
  color: #58616b;
  background-color: #f8fbfd;
  margin: 0;
  padding: 16px 0;
  overflow-x: auto;
}

pre.Pre_inlinePre__hZCAy {
  display: inline-block;
  background-color: #d4e5fe;
  color: #0631cb;
  padding: 0 5px;
}

pre.Pre_pre__14neh > div:before {
  counter-increment: line;
  display: inline-block;
  border-right: 1px solid #bdd7f2;
  padding-right: 10px;
  margin-right: 10px;
  color: #97aeb8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

pre.Pre_pre__14neh > div:nth-of-type(-n + 999):before {
  content: counter(line);
}

pre.Pre_pre__14neh > div:nth-of-type(-n + 99):before {
  content: " " counter(line);
}

pre.Pre_pre__14neh > div:nth-of-type(-n + 9):before {
  content: "  " counter(line);
}

pre.ConsoleOutput_editableContainer__2GxFF {
  min-height: 300px;
}

.ConsoleOutput_stdoutIgnore__2refb {
  color: #97aeb8;
}

.ConsoleOutput_stdoutInput__1JMbZ {
  background-color: #d4e5fe;
  color: #0631cb;
  cursor: pointer;
}

.ConsoleOutput_stdoutEquals__1a2TA {
  color: #58616b;
}

.ConsoleOutput_stdoutContains__2QFLj {
  background-color: #e8ecfd;
  color: #5c64ba;
  cursor: pointer;
}

.ConsoleOutput_popover__2_dD7 {
  font-family: "Lato", sans-serif;
  background-color: #fff;
  color: #58616b;
  box-shadow: 0 0 10px 2px rgba(232, 232, 232, 1);
  padding: 10px;
  border-radius: 3px;
}

.ConsoleOutput_title__3LPss {
  display: flex;
  justify-content: space-between;
}

.ConsoleOutput_toggleMode__3p7rj {
  display: flex;
  align-items: center;
  color: #0f1978;
  cursor: pointer;
}

.ConsoleOutput_toggleMode__3p7rj:hover {
  text-decoration: underline;
}

.ConsoleOutput_toggleMode__3p7rj > svg {
  margin-right: 3px;
}

.ConsoleOutput_line__19CzZ {
  position: relative;
}

.ConsoleOutput_configureLine__4J8Tb {
  visibility: hidden;
  font-family: "Lato", sans-serif;
  position: absolute;
  left: 30px;
  top: 2px;
}

.ConsoleOutput_configureLine__4J8Tb svg {
  color: #0f1978;
  cursor: pointer;
}

.ConsoleOutput_line__19CzZ:hover > .ConsoleOutput_configureLine__4J8Tb {
  visibility: visible;
}

.ConsoleOutput_popover__2_dD7 {
  background-color: #fff;
  padding: 10px;
}

.Input_text__35ej_ {
  display: block;
  height: 36px;
  border: 1px solid #bacdd7;
  border-radius: 3px;
  min-width: 250px;
  padding: 0 10px;
  color: #58616b;
}

.Input_text__35ej_.Input_compact__1Tqsk {
  min-width: auto;
  width: 165px;
}

.Input_text__35ej_.Input_wide__1Z4lA {
  width: 100%;
}

.Input_helpIcon__XVNqG {
  margin-left: 5px;
  font-size: 12px;
}

.Input_popover__2HevI {
  text-align: left;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px #dadada;
  min-width: 200px;
}

.Input_text__35ej_::-webkit-input-placeholder {
  color: #a9c0cf;
}

.Input_text__35ej_::-ms-input-placeholder {
  color: #a9c0cf;
}

.Input_text__35ej_::placeholder {
  color: #a9c0cf;
}

.OutputMatching_container__1pSpN {
    font-size: 14px;
}

.OutputMatching_actionBar__3Lrxc {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.OutputMatching_ioFiles__W0q9g {
    display: flex;
    justify-content: space-between;
}

.OutputMatching_ioFiles__W0q9g > div:first-child {
    margin-right: 20px;
}

.OutputMatching_ioFiles__W0q9g > div {
    flex: 1 1;
}
.List_labelContainer__2_HVZ {
  display: flex;
  flex: 1 1;
}

.List_labelContainer__2_HVZ > div:first-child {
  margin-right: 10px;
}

.List_labelContainer__2_HVZ > div.List_labelContent__1XH2A {
  width: 100%;
}

.List_item__2wgV2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  border-bottom: 1px solid #dbedf6;
  padding: 20px 0;
}

.List_list__2Z4Nw > .List_item__2wgV2:last-child {
  border-bottom: none;
}

.List_item__2wgV2.List_noBorder__38V0B {
  border: none;
}

.List_actions__2ib0- > span {
  margin-right: 15px;
  cursor: pointer;
}

.List_actions__2ib0- > span:last-child {
  margin-right: 0;
}

.Accordion_panel__2OOCb {
    border-bottom: 1px solid #CECECE;
    padding: 20px;
}

.Accordion_panel__2OOCb.Accordion_compact__2GS_u {
    padding: 0;
}

.Accordion_panel__2OOCb:last-child {
    border-bottom: none;
}

.Accordion_panelOverview__1ggqU {
    display: flex;
    justify-content: space-between;
}

.Accordion_panelOverview__1ggqU > div {
    margin-right: 10px;
}

.Accordion_panelOverview__1ggqU > div:last-child {
    margin-right: 0;
}

.Accordion_panelChevron__17Hsu {
    display: inline-flex;
    justify-content: flex-end;
    margin-top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.Accordion_panelContent__3zNCT {
    padding-top: 20px;
}
.WebUITests_newSequence__j9IjA {
  display: flex;
  align-items: flex-end;
}

.WebUITests_sequence__27rx_ {
  margin: 0 -20px;
}

.WebUITests_sequenceStep__1wne- {
  background-color: #f8fbfd;
  padding: 20px;
}

.WebUITests_flex__31OF1 {
  display: flex;
}

.ResultSet_failedIcon__3ALO2 {
    color: #FD3751;
    margin-right: 5px;
}

.ResultSet_successIcon__q1GDW {
    color: #1CD7C3;
    margin-right: 5px;
}

.ResultSet_result__2AK3b {
    display: flex;
    align-items: center;
}

.ResultSet_actualOutput__3BbIe {
    margin-left: 20px;
}
.Indicators_indicator__3RWJ_ {
  display: inline-block;
  min-height: 12px;
  min-width: 12px;
  border-radius: 6px;
  border: 2px solid #e4e4e4;
  margin-right: 5px;
  -webkit-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

.Indicators_indicator__3RWJ_.Indicators_neutral__22Qun {
  background-color: #97aeb8;
}

.Indicators_indicator__3RWJ_.Indicators_failed__amd5g {
  background-color: #fd3751;
}

.Indicators_indicator__3RWJ_.Indicators_success__CLV2G {
  background-color: #1cd7c3;
}

.Indicators_indicator__3RWJ_.Indicators_inProgress__3PdJn {
  background-color: #0631cb;
  -webkit-animation: Indicators_inProgress__3PdJn 1s infinite;
          animation: Indicators_inProgress__3PdJn 1s infinite;
}

@-webkit-keyframes Indicators_inProgress__3PdJn {
  0% {
    border-color: #e4e4e4;
  }
  50% {
    border-color: #0631cb;
  }
  100% {
    border-color: #e4e4e4;
  }
}

@keyframes Indicators_inProgress__3PdJn {
  0% {
    border-color: #e4e4e4;
  }
  50% {
    border-color: #0631cb;
  }
  100% {
    border-color: #e4e4e4;
  }
}

.TestCaseSet_tabListContainer__3TYLZ {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbedf6;
  padding: 8px 20px 0 20px;
}

.TestCaseSet_tabPanelContainer__ah8Fd {
  padding: 20px;
}

.TestCaseSet_tabPanelContainer__ah8Fd.TestCaseSet_docked__1bkxt {
  height: 250px;
  overflow-y: auto;
}

.TestCaseSet_tabPanelContainer__ah8Fd.TestCaseSet_docked__1bkxt.TestCaseSet_collapsed__1ujqb {
  display: none;
}

.TestCaseSet_activeView__1XzSl {
  font-weight: bold;
}

.TestCaseSet_inactiveView__3fNMR {
  color: #5270ff;
}

.TestCaseSet_collapseIcon__1cArW {
  font-size: 12px;
  cursor: pointer;
  color: #0b212f;
}

.TextArea_container__3P5wy {
    width: 100%;
    text-align: left;
}

.TextArea_text__tJowh {
    display: block;
    height: 36px;
    border: 1px solid #BACDD7;
    border-radius: 3px;
    padding: 10px;
    color: #58616B;
    width: 100%;
    min-height: 100px;
}

.TextArea_text__tJowh::-webkit-input-placeholder {
    color: #a9c0cf;
}

.TextArea_text__tJowh::-ms-input-placeholder {
    color: #a9c0cf;
}

.TextArea_text__tJowh::placeholder {
    color: #a9c0cf;
}
.Support_support__3W7lh {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #2734a8;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}

.Support_centered__S9K2N {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.LoadingBar_loadingBar__3DlV_ {
    position: fixed;
    top: 0;
    left: 0;
    height: 1px;
    background-color: #0631CA;
    box-shadow: 0 0 1px 1px #0631CA;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    -webkit-transition: width 300ms ease-out;
    transition: width 300ms ease-out;
}

.BaseLayout_container__3Cfk6 {
  display: flex;
  min-height: 100%;
  min-width: 1000px;
}

.BaseLayout_main__2zpE7 {
  display: flex;
  flex: 1 1;
  margin-left: 210px;
}

.BaseLayout_centered__2hrPH {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.BaseLayout_nav__1Oe15 {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-basis: 210px;
  width: 210px;
  flex-shrink: 0;
  background-color: #0e1352;
  padding: 20px;
  overflow-y: auto;
  z-index: 999;
}

.BaseLayout_coreNav__1NEO0 {
  flex: 1 1;
  padding-bottom: 50px;
}

.BaseLayout_navLink__1wv_- {
  display: flex;
  height: 32px;
  align-items: center;
  color: #fff;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BaseLayout_activeNavLink__r6j11 {
  color: #8c96ec;
}

.BaseLayout_navTitleContainer__1YCSk {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h5.BaseLayout_navTitle__22-yt {
  color: #8c96ec;
}

.BaseLayout_navHr__1R1VM {
  border-color: #2834a8;
  margin: 10px -20px;
}

.BaseLayout_addCourse__3UfB4 {
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

.BaseLayout_content__1ktcE {
  padding: 20px 40px;
  flex-grow: 1;
}

.BaseLayout_sidebar__3pnJT {
  flex-basis: 300px;
  flex-shrink: 0;
  padding: 20px 20px 20px 0;
}

@media (max-width: 1260px) {
  .BaseLayout_sidebar__3pnJT {
    display: none;
  }
}

.BaseLayout_accountSubNav__msBgE {
  margin: -20px;
  padding: 10px 20px;
  background-color: #2834a8;
}

.BaseLayout_accountSubNavSkeleton__1QUaR {
  height: 66px;
  margin: -20px;
  background-color: #2834a8;
}

h5.BaseLayout_lightText__qS1Ue,
span.BaseLayout_lightText__qS1Ue,
.BaseLayout_lightText__qS1Ue {
  color: #fff;
}

.BaseLayout_contentHeader__3jxao {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BaseLayout_actionsContainer__1t1rY {
  display: flex;
}

.BaseLayout_pill__1Owfl {
  color: #1cd7c3;
  border-radius: 12px;
  font-size: 14px;
  margin-top: 2px;
}

.CreateCourseForm_container___cWl3 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.CreateCourseForm_back__1wPpD {
  position: absolute;
  top: 20px;
  left: 20px;
}

.CreateCourseForm_content__3oKm5 {
  width: 440px;
  text-align: center;
  padding: 80px 0;
}

.CreateCourseForm_form__3jk1e {
  color: #000;
  text-align: left;
  padding: 0 20px;
}

.CreateCourseForm_flex__2znV- {
  display: flex;
  justify-content: space-between;
}

.CreateCourseForm_actionBar__1Xhv3 {
  margin: 0 auto;
  width: 250px;
}

.CreateCourseForm_subtitle__6nfqN {
  margin: 2px 0 0 18px;
  font-size: 14px;
}

.CreateCourseForm_seatsEditor__3vzqe {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.CreateCourseForm_seatsEditor__3vzqe > div:nth-child(1) {
  flex: 1 1;
}

.CreateCourseForm_seatsEditor__3vzqe > div:nth-child(2) {
  margin-right: 20px;
}

input[type="text"].CreateCourseForm_studentsInput__1eGpu {
  width: 60px;
}

.CreateCourseForm_fontSm__2QT5N {
  font-size: 14px;
}

.CreateCourseForm_totalPrice__2TDYC {
  padding-bottom: 6px;
  font-weight: bold;
  min-width: 50px;
  text-align: right;
}

.CreateCourseForm_cardForm__27etX {
  border: 1px solid #bacdd7;
  border-radius: 4px;
  padding: 10px;
}

.react-datepicker {
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.react-datepicker__time-container,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100px;
}

.react-datepicker__navigation--next--with-time {
  right: 110px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #0631ca;
}

.AddressForm_formFlex__3wBON {
  display: flex;
}

.AddressForm_formFlex__3wBON input {
  min-width: auto;
  width: 140px !important;
}

.ExerciseOverviewPage_testCaseSetContainer__26KON {
    box-shadow: 0 0 10px 2px rgba(232,232,232,1);
    border-radius: 5px;
}

.ExerciseOverviewPage_sidebar__2TVvy {
    display: flex;
    align-items: flex-end;
    height: 100%;
}
.Node_label__3AFOD {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border-radius: 2px;
}

.Node_label__3AFOD > div {
  display: flex;
}

.Node_indentation__U9LYE {
  padding-left: 20px;
}

.Node_file__2x6-s {
}

.Node_label__3AFOD.Node_file__2x6-s:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.05);
}

.Node_labelIcon__14GWm {
  margin-right: 5px;
}

.Node_fileActionBtn__2gHGr {
  margin-left: 10px;
  visibility: hidden;
}

.Node_label__3AFOD.Node_file__2x6-s:hover .Node_fileActionBtn__2gHGr {
  visibility: visible;
}

.ConfirmAction_popover__3-89- {
    text-align: left;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px #dadada;
    min-width: 200px;
}

.ConfirmAction_wide__58Dd4 {
    min-width: 300px;
}

.ConfirmAction_error__3zM7j {
    color: #fd505a;
}
.FileBrowser_container__17vjo {
  min-width: 220px;
  background: #e0f0f7;
  overflow: auto;
}

.FileBrowser_actionBar__1MzoP {
  display: flex;
  align-items: center;
  height: 40px;
  background: #e8f3f9;
  padding: 5px 20px;
  border-bottom: 2px solid #ddeef6;
}

.FileBrowser_padded__1IRXO {
  padding: 20px;
}

.FileBrowser_newFileContainer__1-Wae {
  background-color: #fff;
  color: #58616b;
  box-shadow: 0 0 10px 2px rgba(232, 232, 232, 1);
  padding: 10px;
  border-radius: 3px;
}

.Editor_container__jcQFw {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: #f8fbfd;
}

.Editor_padded__1NLdF {
  padding: 20px;
}

.Editor_tabs__2vTvS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;
  border-bottom: 2px solid #eaf4f9;
  background-color: #fff;
  font-size: 14px;
}

.Editor_saveStatus__3P2Yc {
  margin-left: 10px;
  margin-top: 4px;
  font-size: 12px;
  color: #1d6b99;
}

.CodeEditor_container__2trop {
    display: flex;
    height: 100%;
    width: 100%;
    color: #0B2230;
}

.AttemptPage_container__3IpW3 {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 1000px;
}

.AttemptPage_header__3tbnj {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background: #0e1352;
}

.AttemptPage_header__3tbnj,
.AttemptPage_header__3tbnj h1,
.AttemptPage_header__3tbnj h3 {
  color: #fff;
  font-weight: normal;
}

.AttemptPage_header__3tbnj > div {
  margin-right: 15px;
}

.AttemptPage_split__-GFtD {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

.AttemptPage_split__-GFtD > div:nth-child(1) {
  flex: 1 1;
}

.AttemptPage_workspaceTestCasesContainer__3vnt7 {
  display: flex;
  flex-direction: column;
}

.AttemptPage_workspaceContainer__36KUu {
  position: relative;
}

.AttemptPage_runTestsContainer__3s5X4 {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.AttemptPage_exerciseOverviewContainer__j2pHf {
  display: flex;
  flex-direction: column;
  flex-basis: 440px;
  background-color: #f1f7fc;
  max-height: 100%;
  overflow-y: auto;
}

.AttemptPage_exerciseOverviewToolbar__27p_y {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 40px;
  padding: 0 20px;
  border-bottom: 2px solid #eaf4f9;
  background-color: #fff;
  font-size: 14px;
}

.AttemptPage_exerciseOverview__1Qsn8 {
  flex: 1 1;
  padding: 20px;
  color: #000;
  border-left: 1px solid #dbedf6;
  overflow: auto;
}

.AttemptPage_testCasesContainer__31By4 {
  border-top: 1px solid #dbedf6;
  z-index: 999;
}

.AttemptPage_actionContainer__m6AgC {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1;
  flex-basis: 100px;
  flex-shrink: 0;
  border-top: 1px solid #dbedf6;
}

.ViewToggles_inactive__4rjBE {
  color: #000;
}

.Card_card__35XLj {
    border-radius: 5px;
    box-shadow: 0 0 5px #dadada;
}
.NestedNav_nav__3UVAU {
    display: flex;
    align-items: center;
    background-color: #0F1978;
    height: 50px;
    border-radius: 5px;
    padding: 0 20px;
    color: #fff;
}

.NestedNav_contained__2X8CJ {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.NestedNav_nav__3UVAU > a {
    display: flex;
    align-items: center;
    color: #fff;
}

.NestedNav_nav__3UVAU > a:hover {
    text-decoration: none;
}

.NestedNav_nav__3UVAU > a > * {
    margin-right: 10px;
}
.CounterPill_counterPill__2KB_4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #D6DAFF;
    color: #0E1353;
    font-weight: bold;
}
.HelpSection_container__1gblE {
  display: flex;
  font-size: 14px;
  line-height: 18px;
}

.HelpSection_icon__Cr4I6 {
  color: #a3a3a4;
  font-size: 16px;
  margin-top: 2px;
}

.HTMLEditor_htmlEditor__2iusY {
    min-height: 300px;
}
.EditExercise_actionBar__yEIIW {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Checkbox_container__3ofx_ {
  display: inline-block;
  cursor: default;
}

.Checkbox_checkbox__3KXxB {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #466ada;
  border-radius: 5px;
  font-size: 12px;
  color: #466ada;
}

.Checkbox_checkbox__3KXxB.Checkbox_pro__1Kv70 {
  border: 1px solid #ff9a4b;
  color: #ff9a4b;
}

.Checkbox_checkbox__3KXxB.Checkbox_disabled__2Xb6c {
  background: #f1f7fc;
  cursor: not-allowed;
}

.Checkbox_label__29sqM {
  margin-left: 5px;
}

.MultiSelector_container__1N4h_ {
    display: flex;
}

.MultiSelector_container__1N4h_ > .MultiSelector_column__1w7VX {
    margin-right: 10px;
}

.MultiSelector_container__1N4h_ > .MultiSelector_column__1w7VX:last-child {
    margin-right: 0;
}

.MultiSelector_column__1w7VX {
    border: 1px solid #DBEDF6;
    border-radius: 5px;
}

.MultiSelector_item__3MOuS {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 10px;
    border-bottom: 1px solid #DBEDF6;
}

.MultiSelector_item__3MOuS:last-child {
    border-bottom: none;
}

.MultiSelector_item__3MOuS > input[type="checkbox"] {
    margin-right: 5px;
}
.TestSuiteEditor_newTestCase__2CGT7 {
    display: flex;
    align-items: flex-end;
}

.TestSuiteEditor_newTestCase__2CGT7 > div {
    margin-right: 10px;
}

.TestSuiteEditor_newTestCase__2CGT7 > div:last-child {
    margin-right: 0;
}

.TestSuiteEditor_actionBar__l7q_r {
    display: flex;
    justify-content: flex-end;
}

.TestSuiteEditor_action__3KBhk {
    color: #0631CB;
}

.TestSuiteEditor_deleteAction__-2EUi {
    color: #FD3751;
}

.TestSuiteEditor_deleteAction__-2EUi.TestSuiteEditor_disabled__2kqs6 {
    color: #58616B;
    cursor: wait;
}

.EditTestCase_testCaseEditor__iJAa6 {
    padding: 20px;
}

.EditTestCase_actionBar__10XqV {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.EditTestCase_metadataEditor__3WwPv {
    padding: 20px;
}

.EditTestCase_metadataActionBar__2_3gg {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.EditTestCase_metadataEditorForm__3mmj9 {
    display: flex;
}

.EditTestCase_metadataEditorForm__3mmj9 > * {
    margin-right: 20px;
}
.ExerciseSet_createExercise__2V7r2 {
    display: flex;
    align-items: flex-end;
}

.ExerciseSet_createExercise__2V7r2 > div {
    margin-right: 10px;
}

.ExerciseSet_createExercise__2V7r2 > div:last-child {
    margin-right: 0;
}

.ExerciseSet_actionBar__2TpkA {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ExerciseSet_action__1pusX {
    color: #0631CB;
}

.ExerciseSet_deleteAction__2bzzx {
    color: #FD3751;
}
.CourseOverview_cardContainer__oshAC {
    padding: 10px 20px;
}

.CourseOverview_labIcon__2VoN8 {
    font-size: 12px;
    margin-right: 3px;
}
.NullStateCard_container__2KCdG {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  min-height: 300px;
}

.NullStateCard_container__2KCdG.NullStateCard_compact__QwQS- {
  min-height: unset;
}

.NullStateCard_pro__2q5B7 {
  background-color: #ffe2d1;
  border: 1px dashed #ff9a4b;
  box-shadow: none;
}

.NullStateCard_content__1DONG {
  max-width: 500px;
}

.NullStateCard_illustration__2BCr3 {
  width: 150px;
}

.NullStateCard_background__2vRWL {
  position: absolute;
  left: 0;
  max-width: 50%;
  max-height: 100%;
  z-index: -1;
  opacity: 0.6;
}

.Notification_notification__17Yi- {
  background-color: #fff0e9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  font-size: 14px;
}

.AnonymousLayout2_page__2RGM3 {
    display: flex;
    min-height: 100%;
    min-width: 1000px;
}

.AnonymousLayout2_side__2gy9c {
    display: flex;
    flex-direction: column;
    background-color: #D8E7FE;
    width: 40%;
    padding: 20px;
}

.AnonymousLayout2_sideContentContainer__1TiZ4 {
    display: flex;
    justify-content: center;
}

.AnonymousLayout2_sideContent__3wtb8 {
    max-width: 400px;
    text-align: center;
}

.AnonymousLayout2_content__2uyYv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 40px 0;
}
.Login_loginForm__vjRVb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    padding: 40px 0;
}

.Login_centered__1GG3_ {
    text-align: center;
}

.Login_forgotPassword__K1mY9 {
    width: 250px;
    text-align: left;
    font-size: 14px;
}
.AnonymousLayout1_page__2p5pj {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 1000px;
  padding: 20px;
}

.AnonymousLayout1_contentContainer__29yVX {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.AnonymousLayout1_content__3FzoT {
  width: 500px;
}

.AnonymousLayout1_center__2ydQY {
  text-align: center;
}

.AnonymousLayout1_card__1yrzl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: 300px;
}

.Signup_center__3mrg9 {
    text-align: center;
}

.SignupVerify_center__2xW4_ {
    text-align: center;
}

.SignupVerify_resendCode__OvrL2 {
    width: 250px;
    text-align: left;
    font-size: 14px;
}
.ResetPassword_center__4Wnyn {
    text-align: center;
}

.ResetPassword_explainer__ngX9l {
    text-align: center;
    width: 400px;
}
table.Table_table__5aD_4 {
    width: 100%;
    border-collapse: collapse;
}

.Table_table__5aD_4 > thead > tr > th {
    padding: 0 20px;
    color: #7AA1C3;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    background-color: #F1F7FC;
}

.Table_table__5aD_4 tr {
    height: 40px;
}

.Table_table__5aD_4 td {
    padding: 0 20px;
    text-align: center;
}

table.Table_table__5aD_4, table.Table_table__5aD_4 td, table.Table_table__5aD_4 th {
    border: 1px solid #DEEBF8;
}

table.Table_table__5aD_4.Table_listBorder__1kIT5, table.Table_table__5aD_4.Table_listBorder__1kIT5 td, table.Table_table__5aD_4.Table_listBorder__1kIT5 th {
    border: none;
    border-bottom: 1px solid #DEEBF8;
}

th.Table_left__PPjLy, td.Table_left__PPjLy {
    text-align: left;
}

th.Table_right__2usAm, td.Table_right__2usAm {
    text-align: right;
}

.Table_table__5aD_4 th.Table_compact__5vJxT, .Table_table__5aD_4 td.Table_compact__5vJxT {
    padding: 0;
}

.Table_table__5aD_4 td.Table_highlight__1Wz4H:hover {
    background-color: #F1F7FC;
}

.Table_table__5aD_4 > thead > tr > th.Table_empty__29TyF {
    background-color: #fff;
}
.Gradebook_failed__2vyaR {
    color: #FF3750;
}

.Gradebook_passed__1XlWs {
    color: #09EDDB;
}

.Gradebook_attemptResult__3mwvJ {
    position: relative;
    width: 100%;
    height: 100%;
}

.Gradebook_attemptResult__3mwvJ > a.Gradebook_attemptLink__3GLai {
    position: absolute;
    right: 0;
    visibility: hidden;
    font-size: 14px;
}

.Gradebook_attemptResult__3mwvJ:hover > a.Gradebook_attemptLink__3GLai {
    visibility: visible;
}
.Assignment_actionTitle__3ynwH {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CourseRoster_enroller__2nY8O {
    display: flex;
}

.CourseRoster_enroller__2nY8O .CourseRoster_invite__1XWpv {
    background-color: #D8E7FF;
    padding: 20px;
}

.CourseRoster_enroller__2nY8O .CourseRoster_inviteCode__2gBpO {
    color: #0631CB;
    font-size: 28px;
    font-weight: bold;
}

.CourseRoster_enroller__2nY8O .CourseRoster_inviteCode__2gBpO > svg {
    font-size: 20px;
}

.CourseRoster_enroller__2nY8O .CourseRoster_enroll__2WdEw {
    padding: 20px
}

.CourseRoster_enroller__2nY8O .CourseRoster_enrollForm__2yQk8 {
    display: flex;
}
.ExerciseSets_actionTitle__J7BjE {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ExerciseSets_exercisesCount__2Z9vx {
  display: flex;
  align-items: center;
}

.ExerciseSets_createExerciseSet__h4NcC {
  display: flex;
  align-items: flex-end;
  text-align: left;
}

.ExerciseSets_centered__1K3Bl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.Profile_padded__22w6B {
    padding: 20px;
}
.CourseManage_padded__2t0nW {
    padding: 20px;
}

.CourseManage_renameContainer__3VlHt {
    display: flex;
    align-items: flex-end;
}
.GettingStarted_panel__24fvV {
  padding-left: 0;
  padding-right: 0;
}

.GettingStarted_flex__2tlOU {
  display: flex;
}

.GettingStarted_videosContainer__3cUvj {
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
}

.GettingStarted_videosContainer__3cUvj iframe {
  flex-basis: 600px;
  height: 330px;
  flex-shrink: 0;
  padding: 20px 0;
}

.CourseEnroll_explainer__2X6A4 {
  text-align: center;
  width: 400px;
}

.CourseEnroll_center__2bpAc {
  text-align: center;
}

.CourseEnroll_cardForm__TcGLa {
  border: 1px solid #90989f;
  border-radius: 4px;
  padding: 10px;
}

.SignupSurvey_center__3Q2iN {
  text-align: center;
}

.SignupSurvey_resendCode__3yj53 {
  width: 250px;
  text-align: left;
  font-size: 14px;
}

.SampleExerciseSets_actionTitle__32iwC {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SampleExerciseSets_exercisesCount__2_7IL {
  display: flex;
  align-items: center;
}

.CS50ExerciseSets_actionTitle__3j5rZ {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CS50ExerciseSets_exercisesCount__3IfzF {
  display: flex;
  align-items: center;
}

.CS50ExerciseSets_plusIcon__3oCRP {
  font-size: 10px;
  margin-top: 2px;
}

html,
body,
#root {
  height: 100%;
  font-family: "Lato", sans-serif;
  color: #58616b;
  line-height: 22px;
}

* {
  box-sizing: border-box;
}

a {
  color: #153bd2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

hr {
  border: 0;
  border-bottom: 1px solid #dbedf6;
}

pre {
  margin: 0;
}

