.container {
    display: flex;
}

.container > .column {
    margin-right: 10px;
}

.container > .column:last-child {
    margin-right: 0;
}

.column {
    border: 1px solid #DBEDF6;
    border-radius: 5px;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 10px;
    border-bottom: 1px solid #DBEDF6;
}

.item:last-child {
    border-bottom: none;
}

.item > input[type="checkbox"] {
    margin-right: 5px;
}