.tabListContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbedf6;
  padding: 8px 20px 0 20px;
}

.tabPanelContainer {
  padding: 20px;
}

.tabPanelContainer.docked {
  height: 250px;
  overflow-y: auto;
}

.tabPanelContainer.docked.collapsed {
  display: none;
}

.activeView {
  font-weight: bold;
}

.inactiveView {
  color: #5270ff;
}

.collapseIcon {
  font-size: 12px;
  cursor: pointer;
  color: #0b212f;
}
