.newTestCase {
    display: flex;
    align-items: flex-end;
}

.newTestCase > div {
    margin-right: 10px;
}

.newTestCase > div:last-child {
    margin-right: 0;
}

.actionBar {
    display: flex;
    justify-content: flex-end;
}

.action {
    color: #0631CB;
}

.deleteAction {
    color: #FD3751;
}

.deleteAction.disabled {
    color: #58616B;
    cursor: wait;
}
