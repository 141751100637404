.loadingBar {
    position: fixed;
    top: 0;
    left: 0;
    height: 1px;
    background-color: #0631CA;
    box-shadow: 0 0 1px 1px #0631CA;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    transition: width 300ms ease-out;
}
