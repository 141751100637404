table.table {
    width: 100%;
    border-collapse: collapse;
}

.table > thead > tr > th {
    padding: 0 20px;
    color: #7AA1C3;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    background-color: #F1F7FC;
}

.table tr {
    height: 40px;
}

.table td {
    padding: 0 20px;
    text-align: center;
}

table.table, table.table td, table.table th {
    border: 1px solid #DEEBF8;
}

table.table.listBorder, table.table.listBorder td, table.table.listBorder th {
    border: none;
    border-bottom: 1px solid #DEEBF8;
}

th.left, td.left {
    text-align: left;
}

th.right, td.right {
    text-align: right;
}

.table th.compact, .table td.compact {
    padding: 0;
}

.table td.highlight:hover {
    background-color: #F1F7FC;
}

.table > thead > tr > th.empty {
    background-color: #fff;
}