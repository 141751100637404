.testCaseEditor {
    padding: 20px;
}

.actionBar {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.metadataEditor {
    padding: 20px;
}

.metadataActionBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.metadataEditorForm {
    display: flex;
}

.metadataEditorForm > * {
    margin-right: 20px;
}