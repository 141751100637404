.container {
    display: flex;
}

.progress {
    height: 10px;
    width: 100px;
    background-color: rgba(28, 215, 195, 0.2);
    margin-right: 2px;
}

.progress.completed {
    background-color: rgba(28, 215, 195, 1.0);
}

.container > .progress:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.container > .progress:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 0;
}