.text {
  display: block;
  height: 36px;
  border: 1px solid #bacdd7;
  border-radius: 3px;
  min-width: 250px;
  padding: 0 10px;
  color: #58616b;
}

.text.compact {
  min-width: auto;
  width: 165px;
}

.text.wide {
  width: 100%;
}

.helpIcon {
  margin-left: 5px;
  font-size: 12px;
}

.popover {
  text-align: left;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px #dadada;
  min-width: 200px;
}

.text::placeholder {
  color: #a9c0cf;
}
