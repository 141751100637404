.delete {
  color: #fd3751;
}

.list {
  font-size: 12px;
}

.pro {
  color: #ff9a4b;
}
