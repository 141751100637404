.container {
    display: inline-block;
    position: relative;
}

.button {
    cursor: pointer;
}

.popover {
    position: absolute;
    z-index: 999;
    animation: fadein 0.1s ease-in;
}

.layover {
    position: absolute;
    z-index: 998;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}