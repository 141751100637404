.container {
  min-width: 220px;
  background: #e0f0f7;
  overflow: auto;
}

.actionBar {
  display: flex;
  align-items: center;
  height: 40px;
  background: #e8f3f9;
  padding: 5px 20px;
  border-bottom: 2px solid #ddeef6;
}

.padded {
  padding: 20px;
}

.newFileContainer {
  background-color: #fff;
  color: #58616b;
  box-shadow: 0 0 10px 2px rgba(232, 232, 232, 1);
  padding: 10px;
  border-radius: 3px;
}
