.enroller {
    display: flex;
}

.enroller .invite {
    background-color: #D8E7FF;
    padding: 20px;
}

.enroller .inviteCode {
    color: #0631CB;
    font-size: 28px;
    font-weight: bold;
}

.enroller .inviteCode > svg {
    font-size: 20px;
}

.enroller .enroll {
    padding: 20px
}

.enroller .enrollForm {
    display: flex;
}