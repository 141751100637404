.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 1000px;
  padding: 20px;
}

.contentContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.content {
  width: 500px;
}

.center {
  text-align: center;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: 300px;
}
