.actionTitle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.exercisesCount {
  display: flex;
  align-items: center;
}

.plusIcon {
  font-size: 10px;
  margin-top: 2px;
}
