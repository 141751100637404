.popover {
    text-align: left;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px #dadada;
    min-width: 200px;
}

.wide {
    min-width: 300px;
}

.error {
    color: #fd505a;
}