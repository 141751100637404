.panel {
  padding-left: 0;
  padding-right: 0;
}

.flex {
  display: flex;
}

.videosContainer {
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
}

.videosContainer iframe {
  flex-basis: 600px;
  height: 330px;
  flex-shrink: 0;
  padding: 20px 0;
}
