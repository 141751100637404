.actionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.exercisesCount {
  display: flex;
  align-items: center;
}

.createExerciseSet {
  display: flex;
  align-items: flex-end;
  text-align: left;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
