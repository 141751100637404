.failed {
    color: #FF3750;
}

.passed {
    color: #09EDDB;
}

.attemptResult {
    position: relative;
    width: 100%;
    height: 100%;
}

.attemptResult > a.attemptLink {
    position: absolute;
    right: 0;
    visibility: hidden;
    font-size: 14px;
}

.attemptResult:hover > a.attemptLink {
    visibility: visible;
}