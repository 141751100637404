.btn {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: #0631ca;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  height: 50px;
  line-height: 50px;
  min-width: 150px;
  border-radius: 25px;
  padding: 0 30px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 200ms linear;
  outline: none;
}

.link {
  color: #0631ca;
  cursor: pointer;
}

.link.small {
  font-size: 12px;
}

.btn.pro,
.btn.pro:hover {
  background-color: #ff9a4b;
}

.btn.pro:focus {
  box-shadow: 0 0 3px 1px #ff9a4b;
}

.btn.disabled,
.btn.disabled:hover {
  cursor: not-allowed;
  background-color: #58616b;
}

.btn > svg {
  margin-right: 5px;
}

.btn.danger {
  background: #fd3751;
  color: #fff;
}

.btn.danger.secondary {
  background: transparent;
  color: #fd3751;
  border: 1px solid #fd3751;
}

.btn.secondary {
  background: transparent;
  color: #0631ca;
  border: 1px solid #0631ca;
}

.btn.secondary.pro {
  color: #ff9a4b;
  border: 1px solid #ff9a4b;
}

.btn.small {
  height: 36px;
  min-width: initial;
  padding: 5px 20px;
  font-size: 14px;
  line-height: initial;
}

a.btn:hover {
  text-decoration: none;
}

.btn.wide {
  width: 100%;
}

.btn:focus {
  box-shadow: 0 0 3px 1px #0631ca;
}

.btn:hover {
  background: #0633ff;
}

.btn.danger:focus {
  box-shadow: 0 0 3px 1px #fd505a;
}

.btn.danger:hover {
  background: #fd505a;
}

.btn.secondary:hover {
  background: #f4f4f4;
}
