.counterPill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #D6DAFF;
    color: #0E1353;
    font-weight: bold;
}