.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border-radius: 2px;
}

.label > div {
  display: flex;
}

.indentation {
  padding-left: 20px;
}

.file {
}

.label.file:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.05);
}

.labelIcon {
  margin-right: 5px;
}

.fileActionBtn {
  margin-left: 10px;
  visibility: hidden;
}

.label.file:hover .fileActionBtn {
  visibility: visible;
}
