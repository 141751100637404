.nav {
    display: flex;
    align-items: center;
    background-color: #0F1978;
    height: 50px;
    border-radius: 5px;
    padding: 0 20px;
    color: #fff;
}

.contained {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.nav > a {
    display: flex;
    align-items: center;
    color: #fff;
}

.nav > a:hover {
    text-decoration: none;
}

.nav > a > * {
    margin-right: 10px;
}