.explainer {
  text-align: center;
  width: 400px;
}

.center {
  text-align: center;
}

.cardForm {
  border: 1px solid #90989f;
  border-radius: 4px;
  padding: 10px;
}
