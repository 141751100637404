.container {
  display: flex;
  min-height: 100%;
  min-width: 1000px;
}

.main {
  display: flex;
  flex: 1;
  margin-left: 210px;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-basis: 210px;
  width: 210px;
  flex-shrink: 0;
  background-color: #0e1352;
  padding: 20px;
  overflow-y: auto;
  z-index: 999;
}

.coreNav {
  flex: 1;
  padding-bottom: 50px;
}

.navLink {
  display: flex;
  height: 32px;
  align-items: center;
  color: #fff;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeNavLink {
  color: #8c96ec;
}

.navTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h5.navTitle {
  color: #8c96ec;
}

.navHr {
  border-color: #2834a8;
  margin: 10px -20px;
}

.addCourse {
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

.content {
  padding: 20px 40px;
  flex-grow: 1;
}

.sidebar {
  flex-basis: 300px;
  flex-shrink: 0;
  padding: 20px 20px 20px 0;
}

@media (max-width: 1260px) {
  .sidebar {
    display: none;
  }
}

.accountSubNav {
  margin: -20px;
  padding: 10px 20px;
  background-color: #2834a8;
}

.accountSubNavSkeleton {
  height: 66px;
  margin: -20px;
  background-color: #2834a8;
}

h5.lightText,
span.lightText,
.lightText {
  color: #fff;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionsContainer {
  display: flex;
}

.pill {
  color: #1cd7c3;
  border-radius: 12px;
  font-size: 14px;
  margin-top: 2px;
}
