pre.editableContainer {
  min-height: 300px;
}

.stdoutIgnore {
  color: #97aeb8;
}

.stdoutInput {
  background-color: #d4e5fe;
  color: #0631cb;
  cursor: pointer;
}

.stdoutEquals {
  color: #58616b;
}

.stdoutContains {
  background-color: #e8ecfd;
  color: #5c64ba;
  cursor: pointer;
}

.popover {
  font-family: "Lato", sans-serif;
  background-color: #fff;
  color: #58616b;
  box-shadow: 0 0 10px 2px rgba(232, 232, 232, 1);
  padding: 10px;
  border-radius: 3px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.toggleMode {
  display: flex;
  align-items: center;
  color: #0f1978;
  cursor: pointer;
}

.toggleMode:hover {
  text-decoration: underline;
}

.toggleMode > svg {
  margin-right: 3px;
}

.line {
  position: relative;
}

.configureLine {
  visibility: hidden;
  font-family: "Lato", sans-serif;
  position: absolute;
  left: 30px;
  top: 2px;
}

.configureLine svg {
  color: #0f1978;
  cursor: pointer;
}

.line:hover > .configureLine {
  visibility: visible;
}

.popover {
  background-color: #fff;
  padding: 10px;
}
