.container {
  display: flex;
  font-size: 14px;
  line-height: 18px;
}

.icon {
  color: #a3a3a4;
  font-size: 16px;
  margin-top: 2px;
}
