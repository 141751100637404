.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  min-height: 300px;
}

.container.compact {
  min-height: unset;
}

.pro {
  background-color: #ffe2d1;
  border: 1px dashed #ff9a4b;
  box-shadow: none;
}

.content {
  max-width: 500px;
}

.illustration {
  width: 150px;
}

.background {
  position: absolute;
  left: 0;
  max-width: 50%;
  max-height: 100%;
  z-index: -1;
  opacity: 0.6;
}
