.labelContainer {
  display: flex;
  flex: 1;
}

.labelContainer > div:first-child {
  margin-right: 10px;
}

.labelContainer > div.labelContent {
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  border-bottom: 1px solid #dbedf6;
  padding: 20px 0;
}

.list > .item:last-child {
  border-bottom: none;
}

.item.noBorder {
  border: none;
}

.actions > span {
  margin-right: 15px;
  cursor: pointer;
}

.actions > span:last-child {
  margin-right: 0;
}
