.newSequence {
  display: flex;
  align-items: flex-end;
}

.sequence {
  margin: 0 -20px;
}

.sequenceStep {
  background-color: #f8fbfd;
  padding: 20px;
}

.flex {
  display: flex;
}
