html,
body,
#root {
  height: 100%;
  font-family: "Lato", sans-serif;
  color: #58616b;
  line-height: 22px;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #153bd2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

hr {
  border: 0;
  border-bottom: 1px solid #dbedf6;
}

pre {
  margin: 0;
}
