.tabList {
    margin: 0;
    padding: 0;
    flex: 1;
}

li.tab {
    display: inline-flex;
    align-items: center;
}

li.tab > svg {
    margin-right: 5px;
    font-size: 14px;
}

li.selectedTab {
    color: #153BD2;
    font-weight: bold;
    border-bottom: 3px solid #153BD2;
    height: 40px;
}