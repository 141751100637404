.indicator {
  display: inline-block;
  min-height: 12px;
  min-width: 12px;
  border-radius: 6px;
  border: 2px solid #e4e4e4;
  margin-right: 5px;
  transition: background-color 200ms linear;
}

.indicator.neutral {
  background-color: #97aeb8;
}

.indicator.failed {
  background-color: #fd3751;
}

.indicator.success {
  background-color: #1cd7c3;
}

.indicator.inProgress {
  background-color: #0631cb;
  animation: inProgress 1s infinite;
}

@keyframes inProgress {
  0% {
    border-color: #e4e4e4;
  }
  50% {
    border-color: #0631cb;
  }
  100% {
    border-color: #e4e4e4;
  }
}
