.panel {
    border-bottom: 1px solid #CECECE;
    padding: 20px;
}

.panel.compact {
    padding: 0;
}

.panel:last-child {
    border-bottom: none;
}

.panelOverview {
    display: flex;
    justify-content: space-between;
}

.panelOverview > div {
    margin-right: 10px;
}

.panelOverview > div:last-child {
    margin-right: 0;
}

.panelChevron {
    display: inline-flex;
    justify-content: flex-end;
    margin-top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.panelContent {
    padding-top: 20px;
}