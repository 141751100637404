.container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.back {
  position: absolute;
  top: 20px;
  left: 20px;
}

.content {
  width: 440px;
  text-align: center;
  padding: 80px 0;
}

.form {
  color: #000;
  text-align: left;
  padding: 0 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.actionBar {
  margin: 0 auto;
  width: 250px;
}

.subtitle {
  margin: 2px 0 0 18px;
  font-size: 14px;
}

.seatsEditor {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.seatsEditor > div:nth-child(1) {
  flex: 1;
}

.seatsEditor > div:nth-child(2) {
  margin-right: 20px;
}

input[type="text"].studentsInput {
  width: 60px;
}

.fontSm {
  font-size: 14px;
}

.totalPrice {
  padding-bottom: 6px;
  font-weight: bold;
  min-width: 50px;
  text-align: right;
}

.cardForm {
  border: 1px solid #bacdd7;
  border-radius: 4px;
  padding: 10px;
}
