.actionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.exercisesCount {
  display: flex;
  align-items: center;
}
