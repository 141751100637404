.page {
    display: flex;
    min-height: 100%;
    min-width: 1000px;
}

.side {
    display: flex;
    flex-direction: column;
    background-color: #D8E7FE;
    width: 40%;
    padding: 20px;
}

.sideContentContainer {
    display: flex;
    justify-content: center;
}

.sideContent {
    max-width: 400px;
    text-align: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 40px 0;
}